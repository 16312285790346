import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FloatingTryForFree, SeriesGrid } from 'src/components/shared';
import SEO from 'src/components/SEO';
import Selectors from 'src/state/root-selectors';
import Actions from 'src/state/root-actions';
import Loader from 'src/components/shared/Loader';
import { isEmpty } from 'lodash';
import Colors from 'src/utils/colors';

const AllSeriesPage = () => {
    const userCohort = useSelector(Selectors.account.userCohort);
    const entitled = useSelector(Selectors.auth.entitled);
    const allSeriesTargeted = useSelector(Selectors.series.getAllSeriesTargeted);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!isEmpty(allSeriesTargeted)) {
            setLoading(false);
        }
    }, [allSeriesTargeted]);

    useEffect(() => {
        dispatch(Actions.series.loadAllSeriesTargeted.trigger());
    }, [userCohort, dispatch]);

    const numSeries = allSeriesTargeted?.length || 0;
    return loading ? (
        <Loader spinnerColor={Colors.black} className="margin-top--large margin-bottom--large" />
    ) : (
        <>
            <SEO title={`All Series`} />
            <SeriesGrid title="All Series" numSeries={numSeries} series={allSeriesTargeted} />
            {!entitled && <FloatingTryForFree />}
        </>
    );
};

export default AllSeriesPage;
